import React from 'react';
import style from './page.module.css';

const Page = ({children, customClass}) => {

  return (
    <div className={`${style.page} ${customClass}`}>
      {children}
    </div>
  );
};

export default Page;