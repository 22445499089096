import { useState } from "react";
import { firebase } from "../../firebase";
import style from './cases.module.css';
import Page from "../../Components/Page/page";
import Section from "../../Components/Section/section";
import Button from "../../Components/Button/button";
import ROUTES from "../../Routes/routes";

const AddCase = () => {
    const [title, setTitle] = useState("");
    const [slug, setSlug] = useState("");
    const [description, setDescription] = useState("");
    const [htmlContent, setHtmlContent] = useState("");
    const db = firebase.firestore();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await db.collection("cases").add({
                title: title,
                slug: slug,
                description: description,
                content: htmlContent,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            });
            alert("Case added successfully!");
            setTitle("");
            setSlug("");
            setDescription("");
            setHtmlContent("");
        } catch (error) {
            console.error("Error adding case: ", error);
            alert("Failed to add case. Please try again.");
        }
    };

    return (
        <Page>
          <Section customClass={style.section}>
            <Button link={ROUTES.add_blog}>Liever een blog toevoegen</Button>
            <form className={style.container} onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="title"
                    placeholder="Title (geen html)"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                />
                <input
                    type="text"
                    name="slug"
                    placeholder="Slug (vb. title-van-de-case spacies worden -)"
                    value={slug}
                    onChange={(e) => setSlug(e.target.value)}
                    
                    required
                />

                <textarea
                    className={style.container_description}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="korte beschrijving (geen html max 50 woorden)"
                    required
                />
                
                <textarea
                    value={htmlContent}
                    onChange={(e) => setHtmlContent(e.target.value)}
                    placeholder="De case (html)"
                    required
                />

                <button type="submit" className={style.button}>Case toevoegen</button>
            </form>
          </Section>
        </Page>
    );
};

export default AddCase;
