import React from 'react';
import style from './input.module.css';

const Input = ({ name, placeholder, customClass, onChange, error }) => {
    return (
        <div className={style.inputContainer}>
            <input 
                name={name} 
                className={`${customClass} ${error ? style.errorBorder : ''}`} 
                placeholder={placeholder} 
                onChange={onChange} 
            />
            {error && <p className={style.errorMessage}>{error}</p>}
        </div>
    );
};

export default Input;
