import React from 'react';

import { Route, Routes } from 'react-router-dom';
import Header from './Components/Header/header';
import Footer from './Components/Footer/footer';
import ROUTES from './Routes/routes';
import './App.css';

import Home from './Pages/Home/home';
import Blogs from './Pages/Blogs/blogs';
import AboutMe from './Pages/AboutMe/aboutme';
import Cases from './Pages/Cases/cases';
import Contact from './Pages/Contact/contact';
import PrivacyPolicy from './Pages/PrivacyPolicy/privacypolicy';

import AddBlog from './AdminPanel/Blogs/blogs';
import AddCase from './AdminPanel/Cases/cases';
import Login from './AdminPanel/Login/login';
import PrivateRoute from './AdminPanel/Auth/auth';
import Cookies from './Components/Cookies/cookies';
import DetailCases from './Components/DetailPage/DetailCases/detailCases';

// TODO: styling of the cookie warning

function App() {

  return (
    <div className='app'>
      <Header/>
      <Routes>
        <Route path={ROUTES.home} element={<Home/>} />
        <Route path={ROUTES.aboutme} element={<AboutMe/>} /> 
        <Route path={ROUTES.cases} element={<Cases/>} />
        <Route path={ROUTES.case.path} element={<DetailCases/>}/>
        <Route path={ROUTES.blogs} element={<Blogs/>} /> 
        <Route path={ROUTES.contact} element={<Contact/>} /> 
        <Route path={ROUTES.privacypolicy} element={<PrivacyPolicy/>} />

        <Route path={ROUTES.login} element={<Login/>} /> 
        <Route
          path={ROUTES.add_blog}
          element={
            <PrivateRoute>
                <AddBlog />
            </PrivateRoute>
          } 
        />
        <Route
          path={ROUTES.add_case}
          element={
            <PrivateRoute>
                <AddCase />
            </PrivateRoute>
          } 
        /> 
      </Routes>

      <Cookies/>
      <Footer/>
    </div>
  );
}

export default App;

