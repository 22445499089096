import react, { useEffect, useState } from "react";
import style from './cookies.module.css';
import { AnalyticsService } from '../../Services/AnalyticsService';


const Cookies = () => {
  const [handledCookieConsent, setHandledCookieConsent] = useState(AnalyticsService.hasChosen());
  useEffect(() => {
    AnalyticsService.load();
  }, []);

    return (
      <>
        {!handledCookieConsent && 
        <div className={style.container}>
          <p>We gebruiken cookies om ons verkeer te analyseren en ervoor te zorgen dat we u de beste ervaring op onze website bieden.</p>
          <div className={style.container_buttons}>
            <button className={style.button} onClick={() => {
              AnalyticsService.enable();
              setHandledCookieConsent(true);
            }}>Accepteer</button><button className={style.secondaireButton} onClick={() => {
              AnalyticsService.disable();
              setHandledCookieConsent(true);
            }}>Weiger</button>
          </div>
        </div>
        } 
      </>

    );
};

export default Cookies;
