const ROUTES = {
  home: "/",
  aboutme: "/over-mij",
  cases: "/cases",
  case: {path: "/cases/:slug", to: "/cases/"},

  blogs: "/blogs",
  blog: {path: "/blogs/:id", to: "/blogs/"},

  contact: "/contact",
  privacypolicy: "/privacy-policy",

  login: '/login',
  add_case: '/nieuwe-case',
  add_blog : '/nieuwe-blog',
  
  
  notFound: "*"
}

export default ROUTES;