class AnalyticsServiceImpl {
    constructor() {
        this.loadedGtag = false;
        this.gtagID = 'G-WG20NMWR4F';
        window.dataLayer = window.dataLayer || [];  

    }

    setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }

        return undefined;
    }

    disable() {
      //  TODO: misschien ga cookies deleten als ze bestaan document. cookie = "ga_=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        this.setCookie('google-analytics-opt-in', 'false', 365);
    }

    enable() {
        this.setCookie('google-analytics-opt-in', 'true', 365);
        this.load();
    }

    hasChosen() {
        const choice = this.getCookie('google-analytics-opt-in');
        return choice !== undefined;
    }

    isOptIn() {
        return this.getCookie('google-analytics-opt-in') === "true";
    }


    load() {
        if(this.isOptIn()) {
            if (this.loadedGtag) return;
            this.gtag('js', new Date());
            this.gtag("consent", "update", {
                analytics_storage: "granted",
                functionality_storage: "granted",
            });
            //console.log('loading analytics');
            this.gtag('config', this.gtagID);
             
            this.gtag('get', this.gtagID, 'client_id', () => {
              console.log('analytics client handshake recieved'); // should only fire if analytics is loaded, hence not if the user opted out.
            });

            if(!document.getElementById("gtag-lib")) {
                // Load gtag.js script.
                const gtagScript = document.createElement('script');
                gtagScript.id = "gtag-lib"
                gtagScript.async = true;
                gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.gtagID;
                const firstScript = document.getElementsByTagName('script')[0];
                firstScript.parentNode.insertBefore(gtagScript, firstScript);

            } 


        }
    }

    gtag() {
        window.dataLayer.push(arguments);
    }



}


export const AnalyticsService = new AnalyticsServiceImpl();