import { useState } from "react";
import { firebase } from "../../firebase";
import style from './blogs.module.css';

import Page from "../../Components/Page/page";
import Section from "../../Components/Section/section";
import Button from "../../Components/Button/button";

const AddBlog = () => {
    const [title, setTitle] = useState("");
    const [slug, setSlug] = useState("");
    const [description, setDescription] = useState("");
    const [htmlContent, setHtmlContent] = useState("");
    const [image, setImage] = useState(null);

    const db = firebase.firestore();
    const storage = firebase.storage();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let imageUrl = "";

            if (image) {
                const imageRef = storage.ref(`blog-images/${image.name}`);
                await imageRef.put(image);
                imageUrl = await imageRef.getDownloadURL();
            }

            await db.collection("blogs").add({
                title: title,
                slug: slug,
                description: description,
                content: htmlContent,
                imageUrl,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            });

            alert("Blog post added successfully!");
            setTitle("");
            setSlug("");
            setDescription("");
            setHtmlContent("");
            setImage(null);
        } catch (error) {
            console.error("Error adding blog: ", error);
            alert("Failed to add blog. Please try again.");
        }
    };

    return (
      <Page>
        <Section customClass={style.section}>
          <Button>Liever een case toegvoegen</Button>
          <form className={style.container} onSubmit={handleSubmit}>
                 <input
                    type="text"
                    name="title"
                    placeholder="Title (geen html)"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                />
                <input
                    type="text"
                    name="slug"
                    placeholder="Slug (vb. title-van-de-case spacies worden -)"
                    value={slug}
                    onChange={(e) => setSlug(e.target.value)}
                    
                    required
                />

                <textarea
                    className={style.container_description}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="korte beschrijving (geen html max 50 woorden)"
                    required
                />
                
              <textarea
                  value={htmlContent}
                  onChange={(e) => setHtmlContent(e.target.value)}
                  placeholder="je blog post (html code"
                  rows="10"
              />
              <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => setImage(e.target.files[0])}
              />
              <button className={style.button} type="submit">Add Blog</button>
          </form>
        </Section>
      </Page>
    );
};

export default AddBlog;
