import React from 'react';
import style from './input.module.css';

const Text = ({ name, placeholder, onChange }) => {
    return (
        <textarea 
            name={name} 
            placeholder={placeholder} 
            onChange={onChange} 
        />
    );
};

export default Text;
