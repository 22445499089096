import React, { useEffect, useState } from 'react';
import style from './cases.module.css';
import Page from '../../Components/Page/page';
import Title from '../../Components/Title/title';
import Section from '../../Components/Section/section';
import Button from '../../Components/Button/button';
import ROUTES from '../../Routes/routes';
import SmallTitle from '../../Components/SmallTitle/smalltitle';
import { Link } from 'react-router-dom';
import { firebase } from '../../firebase';

const Cases = () => {
    const [cases, setCases] = useState([]);

    useEffect(() => {
        const fetchCases = async () => {
            try {
                const db = firebase.firestore();
                const snapshot = await db.collection('cases').get();
                const casesData = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setCases(casesData);
            } catch (error) {
                console.error("Error fetching cases:", error);
            }
        };

        fetchCases();
    }, []);

    return (
        <Page>
            <Section>
                <Title>Cases</Title>
                <div className={style.container}>
                    {cases.map(({ id, title, description, slug }) => (
                        <Link
                            key={id}
                            to={`${ROUTES.case.to}${slug}`}
                            className={style.container_item}
                        >
                            <SmallTitle>{title}</SmallTitle>
                            <p>{description}</p>
                        </Link>
                    ))}
                </div>
                <Button link={ROUTES.contact}>Deel jouw uitdaging in een vrijblijvend gesprek</Button>
            </Section>
        </Page>
    );
};

export default Cases;
