import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { firebase } from '../../../firebase';
import Page from '../../../Components/Page/page';
import Section from '../../../Components/Section/section';
import style from './detailCases.module.css';

const CaseDetail = () => {
    const { slug } = useParams();
    const [caseDetail, setCaseDetail] = useState(null);

    useEffect(() => {
        const fetchCaseDetail = async () => {
            try {
                const db = firebase.firestore();
                const querySnapshot = await db.collection('cases').where('slug', '==', slug).get();
                if (!querySnapshot.empty) {
                    setCaseDetail(querySnapshot.docs[0].data());
                } else {
                    console.error("No case found with this slug.");
                }
            } catch (error) {
                console.error("Error fetching case detail:", error);
            }
        };

        fetchCaseDetail();
    }, [slug]);

    if (!caseDetail) {
        return <Page><Section><p>Loading...</p></Section></Page>;
    }

    const { content } = caseDetail;

    return (
        <Page>
            <Section>
                <div 
                    className={style.container} 
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            </Section>
        </Page>
    );
};

export default CaseDetail;
