import React from 'react';
import style from './button.module.css';
import { Link } from 'react-router-dom';

const Button = ({children, link, customClass}) => {
    return (
      <Link to={link} className={`${style.button} ${customClass}`}>
        {children}
      </Link>
    );
};

export default Button;