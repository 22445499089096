import { useState } from "react";
import { firebase } from "../../firebase";
import style from './login.module.css';

import Page from "../../Components/Page/page";
import { useNavigate } from "react-router-dom";
import Section from "../../Components/Section/section";
const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await firebase.auth().signInWithEmailAndPassword(email, password);
            navigate('/nieuwe-case');

        } catch (error) {
            console.error("Login failed:", error.message);
            alert("Login failed. Please check your credentials.");
        }
    };

    return (
      <Page>
        <Section>
            <form className={style.container} onSubmit={handleLogin}>
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button className={style.button} type="submit">Login</button>
            </form>
        </Section>
      </Page>

    );
};

export default Login;
